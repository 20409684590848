/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";
@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli');
@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/bootstrap.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import 'swiper/css/bundle';
@import '@ali-hm/angular-tree-component/css/angular-tree-component.css';


body {
  font-family: 'Muli';
}

.hideColumn{
  display: none !important;
}

button:hover {
  background-color: #8b22ff !important;
}

button {
  background-color: #f42e9c !important;
  color: white !important;
  font-family: 'Muli';
}

.aside-color {
  background-color: #23103b !important;
}

.non-title-font {
  font-family: 'Muli';
}

.font {
  font-family: 'Mulish';
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.ml-0 {
  margin-left: ($spacer * 0) !important;
}

.text-blue-etail-1 {
  color: #3e008a !important;
}

.text-blue-etail-2 {
  color: #7a4faf !important;
}

.top-conainer-validation-page {
  margin-top: 10%;
}

@media only screen and (max-width: 490px) {

  .logo3-style-mobile {
    width: 47%;
    height: 30%;
  }
  .page-title-size {
    font-size: x-small !important;
  }
}

@media only screen and (min-width: 491px) {

  .logo3-style {
    width: 40%;
    height: 50%;
  }

  .page-title-size {
    font-size: 15px !important;
  }
}

.demo-chart {
  height: 490px !important;
  position: relative
}

.apexcharts-legend.apx-legend-position-bottom{
  // padding-top: 20px ;
  // display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
}

.apexcharts-legend-series {
  display: block !important;
}

.content-div {
  margin-top: 60px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
}

/* Start : style icon under construnction */
.div-construction{
  margin-top:10rem;
}
.main-title{
  font-family: 'Mulish';
  text-align: center;
  margin-top: 0.1rem;
  margin-bottom: 8rem;
  text-shadow:
  1px 0px 1px #cfcde0, 0px 1px 1px #eee,
  2px 1px 1px #cfcde0, 1px 2px 1px #eee,
  3px 2px 1px #cfcde0, 2px 3px 1px #eee,
  4px 3px 1px #cfcde0, 3px 4px 1px #eee,
  5px 4px 1px #cfcde0, 4px 5px 1px #eee,
  6px 5px 1px #cfcde0, 5px 6px 1px #eee,
  7px 6px 1px #cfcde0;
}

.main-title h1{
  font-size: 7rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #555;
}

.main-title h2{
  font-size: 2rem;
  font-weight: bolder;
  text-transform: uppercase;
  color:#3f3d56;
}

.svg-img{
 display: block;
 margin: auto;
}

svg{
  display: block;
 margin: auto;
}

#clock{
  animation: clockHand 5s infinite linear;
  transform-box: fill-box;
  transform-origin: bottom;
}

#leftTree, #righTree{
  animation: tree 2s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: bottom;
}

#man{
  animation: manBody 1s ease-in-out infinite alternate;
  transform-box: fill-box;
  transform-origin: bottom;
}

#pc-circle{
  fill: #6ace66;
  stroke-width: 4;
  animation: change-light 4s linear infinite alternate;
}

@keyframes clockHand{
  from{
      transform: rotateZ(0deg);
  }
  from{
      transform: rotateZ(-360deg);
  }
}

@keyframes manBody{
  from{
      transform: rotateX(0deg);
  }
  to{
      transform: rotateX(10deg);
  }
}

@keyframes tree{
  from{
      transform: rotateZ(10deg);
  }
  to{
      transform: rotateZ(-20deg);
  }
}

@keyframes change-light {
  0% {
      stroke: #cd61f8;
    }
    25% {
      stroke: #872ff5;
    }
    75% {
      stroke: #65c7f9;
    }
    100% {
      stroke: #fe41a0;
    }
}

/* Media Queries */

@media (min-width: 640px){
  .main-title h2{
      font-size: 3rem;
      font-weight: 300;
      text-transform: uppercase;
  }
  }

  @media (min-width: 768px){
      .main-title h2{
          font-size: 3rem;
          font-weight: 300;
          text-transform: uppercase;
      }
  }

  @media (min-width: 1024px){
      .main-title h2{
          font-size: 3rem;
          font-weight: 300;
          text-transform: uppercase;
      }
  }

  @media (min-width: 1200px){
      .main-title h2{
          font-size: 2rem;
          font-weight: 300;
      }

      .main-title{
          text-align: center;
          margin-top: 0rem;
          margin-bottom: 4rem;
      }
  }

  /* End : style icon under construnction */

  /* Start : icons apexcharts */
  .apexcharts-zoomout-icon +div{
    opacity: 1 !important;
    transform: scale(0.85);
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 24px;
    text-align: center;
  }

  .apexcharts-zoomout-icon +div svg{
    fill: #6E8192;
  }

  .apexcharts-zoomout-icon +div.apexcharts-selected svg{
    fill: #008FFB;
  }

  .apexcharts-zoomout-icon +div +div{
    opacity: 1 !important;
    transform: scale(0.62);
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 24px;
    text-align: center;
  }

  .apexcharts-zoomout-icon +div +div svg{
    stroke: #6E8192;
    fill: #fff;
    stroke-width: 2;
  }

  .apexcharts-zoomout-icon +div +div.apexcharts-selected svg{
    stroke: #008FFB;
  }
  /* End : icons apexcharts */



.daterangepicker .ranges li.active {
  background-color: #8b5cda !important;
  color: #fff;
}

.daterangepicker td.in-range {
  background-color: #e2d8f454 !important;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #8b5cda !important;
  border-color: transparent;
  color: #fff;
}

.btn-style{
  width: 70px !important;
  margin-top: 1px !important;
}

.svg-blue [fill]:not(.permanent):not(g){
  transition: fill 0.3s ease;
  fill: #089cf3 !important;
}
.svg-gray [fill]:not(.permanent):not(g){
  transition: fill 0.3s ease;
  fill: #A1A5B7 !important;
}

.filter-status{
  position: relative;
  padding: 5px;
}
.scrollTop-container{
  margin-top: 15px !important;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 13px !important;
  font-weight: bold;
  color:#b58cf9;
}

.swiper-button-next, .swiper-button-prev {
  width: calc(var(--swiper-navigation-size) / 99 * 1) !important;
  margin-top: calc(0px - var(--swiper-navigation-size) / 3) !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
  background:#b58cf9 !important;
}

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.05) !important;
}

.swiper-horizontal >
.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite{
  height: 2px !important;
}

@media only screen
and (max-width: 450px) {
    .ngx-datatable.material .datatable-footer .datatable-pager a {
        height: 22px;
        min-width: 10px !important;
        line-height: 22px;
        padding: 0 0px !important;
        border-radius: 3px;
        margin: 6px 3px !important;
        text-align: center;
        vertical-align: top;
        color: rgba(0, 0, 0, 0.54);
        text-decoration: none;
        vertical-align: bottom;
    }

    .ngx-datatable.material .datatable-footer .datatable-pager {
         margin: 0 1px !important;
    }

    .ngx-datatable.material .datatable-footer .page-count {
        line-height: 50px;
        height: 50px;
        margin: 1px !important;
        padding: 0 !important;
    }
}

.apexcharts-legend-series[seriesname="Roasxsp"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="Roasxsb"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="Roasxsd"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="RequestedxAmount"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="AcceptedxAmount"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="ReceivedxAmount"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="OutstandingxAmount"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="RequestedxQuantity"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="AcceptedxQuantity"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="ReceivedxQuantity"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="OutstandingxQuantity"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="GlancexView"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="ShippedxCOGS"] .apexcharts-legend-marker ,
.apexcharts-legend-series[seriesname="ShippedxRevenue"] .apexcharts-legend-marker {
  width: 12px !important;
  height: 12px !important;
  border-radius: 0 !important;
}

#sellOutChart .apexcharts-tooltip-series-group[style*="order: 1"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#sellOutChart .apexcharts-tooltip-series-group[style*="order: 3"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#galanceViewChart .apexcharts-tooltip-series-group[style*="order: 1"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#performanceLast4weeks .apexcharts-tooltip-series-group[style*="order: 4"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#performanceLast4weeks .apexcharts-tooltip-series-group[style*="order: 5"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#performanceLast4weeks .apexcharts-tooltip-series-group[style*="order: 6"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInValueChart .apexcharts-tooltip-series-group[style*="order: 1"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInValueChart .apexcharts-tooltip-series-group[style*="order: 2"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInValueChart .apexcharts-tooltip-series-group[style*="order: 3"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInValueChart .apexcharts-tooltip-series-group[style*="order: 4"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInQuantityChart .apexcharts-tooltip-series-group[style*="order: 1"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInQuantityChart .apexcharts-tooltip-series-group[style*="order: 2"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInQuantityChart .apexcharts-tooltip-series-group[style*="order: 3"][style*="display: flex;"] .apexcharts-tooltip-marker ,
#salesInQuantityChart .apexcharts-tooltip-series-group[style*="order: 4"][style*="display: flex;"] .apexcharts-tooltip-marker {
  border-radius: 0 !important;
}

.apexcharts-canvas {
  touch-action: none;
  user-select: none;
}

.text-muted {
  color: rgb(167 171 176 / 75%) !important;
}